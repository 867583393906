import styled, { system } from '@xstyled/styled-components';
import _Link from 'components/link/Link';
import { motion } from 'framer-motion';

export const Link = styled(_Link)`
	font-size: xl;
	color: text;
	text-decoration: none;
	transition: 0.5s;
	font-weight: 400;
	letter-spacing: 1;
	padding-top: 0.5;
	padding-bottom: 0.5;
	border-top: 2px solid;
	border-top-color: transparent;
	line-height: 32px;

	@media (min-width: md) {
		&.active {
			border-top: 2px solid;
			border-top-color: primary;
		}
	}

	@media (min-width: _) and (max-width: md) {
		&.active {
			color: primary;
		}
	}

	:hover {
		color: primary;
	}
`;

export const MobileHeader = styled(motion.header)`
	overflow: hidden;
	${system}
`;
