import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';

import * as Styled from './style/Link.styles';

export default function Link({
	href,
	isExternal,
	isUnderlined,
	children,
	...props
}) {
	const [isHovered, setIsHovered] = useState(false);

	const _onHoverIn = () => {
		setIsHovered(true);
	};

	const _onHoverOut = () => {
		setIsHovered(false);
	};

	return React.cloneElement(
		isExternal ? <Styled.Link /> : <Styled.LinkRouter />,
		{
			...(isExternal
				? { href: href, target: '_blank', rel: 'noopener noreferrer' }
				: { to: href }),
			onFocus: _onHoverIn,
			onBlur: _onHoverOut,
			onMouseOver: _onHoverIn,
			onMouseOut: _onHoverOut,
			...props,
		},
		<>
			{children}
			<AnimatePresence>
				{isUnderlined && isHovered && (
					<Styled.Underline
						variant={props.variant}
						color={props.color}
						initial={{ x: '100%', width: 0 }}
						animate={{ x: 0, width: '100%' }}
						exit={{ x: '100%', width: 0 }}
						transition={{
							duration: 0.5,
						}}
					/>
				)}
			</AnimatePresence>
		</>
	);
}

Link.propTypes = {
	href: PropTypes.string.isRequired,
	isExternal: PropTypes.bool,
	isUnderlined: PropTypes.bool,
	children: PropTypes.node,
};

Link.defaultProps = {
	href: null,
	isExternal: false,
	isUnderlined: true,
	children: null,
};
