import React, { useEffect, useMemo, useState } from 'react';
import { useColor, useColorMode, useDown, x } from '@xstyled/styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Player } from '@lottiefiles/react-lottie-player';
import { Spiral as Burger } from 'hamburger-react';
import { AnimatePresence } from 'framer-motion';

import colorSwitcher from 'assets/lottie/color-switcher.json';

import * as Styled from './style/Header.styles';

export default function Header() {
	const [lottie, setLottie] = useState();
	const [isBurgerOpen, setIsBurgerOpen] = useState();
	const [hasSetLottie, setHasSetLottie] = useState(false);

	const [colorMode, setColorMode] = useColorMode();

	const isMobile = useDown('md');

	const primary = useColor('primary');

	const {
		contentfulLabels: {
			labels: { items },
		},
	} = useStaticQuery(graphql`
		query NavLabel {
			contentfulLabels(pageName: { eq: "nav" }) {
				labels {
					items {
						label
						url
						isDisabled
					}
				}
			}
		}
	`);

	useEffect(() => {
		if (!!lottie && !hasSetLottie) {
			lottie?.playSegments(
				colorMode === 'default' ? [105, 110] : [50, 55],
				true
			);
			setHasSetLottie(true);
		}
	}, [colorMode, hasSetLottie, lottie]);

	const resolveItems = useMemo(
		() => (
			<x.ul
				display="flex"
				w={{ _: '100%', md: '80%' }}
				flexDirection={{ _: 'column', md: 'row' }}
				alignItems={{ _: 'center', md: 'unset' }}
				ml={{ _: 0, md: -6 }}
				mr={{ _: 0, md: 6 }}
			>
				{items?.map(({ label, url }, i) => (
					<x.li
						display="inherit"
						key={`nav-item_${i}`}
						px={{ _: 0, md: 6 }}
						py={{ _: 2, md: 0 }}
					>
						<Styled.Link
							href={url}
							activeClassName="active"
							isUnderlined={!isMobile}
						>
							{label}
						</Styled.Link>
					</x.li>
				))}
			</x.ul>
		),
		[items, isMobile]
	);

	const _onColorSwitchClick = () => {
		if (colorMode === 'default') {
			lottie?.playSegments([0, 55], true);
		} else {
			lottie?.playSegments([55, 110], true);
		}
		setColorMode(colorMode === 'default' ? 'dark' : 'default');
	};

	return isMobile ? (
		<>
			<x.div py={8} maxWidth={1200} mx="auto">
				<x.div display="flex" justifyContent="flex-end">
					<x.button
						bg="transparent"
						transition="0.5s"
						onClick={_onColorSwitchClick}
						p={0}
						w="inherit"
						outline="none"
						zIndex={999999}
						mr={2}
					>
						<Player
							lottieRef={(instance) => {
								setLottie(instance);
							}}
							autoplay={false}
							loop={false}
							keepLastFrame={true}
							speed={3}
							src={colorSwitcher}
							style={{
								width: '40px',
							}}
						/>
					</x.button>
					<Burger
						toggled={isBurgerOpen}
						toggle={setIsBurgerOpen}
						color={primary}
					/>
				</x.div>
				<AnimatePresence>
					{isBurgerOpen && (
						<Styled.MobileHeader
							initial={{ height: 0 }}
							animate={{ height: 'auto' }}
							exit={{ height: 0 }}
							transition={{
								duration: 0.5,
							}}
							bg="backgroundLight"
							mx={-6}
						>
							<x.div py={8}>{resolveItems}</x.div>
						</Styled.MobileHeader>
					)}
				</AnimatePresence>
			</x.div>
		</>
	) : (
		<x.header
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			pt={10}
			pb={12}
			maxWidth={1200}
			mx="auto"
		>
			{resolveItems}
			<x.button
				bg="transparent"
				transition="0.5s"
				onClick={_onColorSwitchClick}
				p={0}
				w="inherit"
				outline="none"
				zIndex={999999}
			>
				<Player
					lottieRef={(instance) => {
						setLottie(instance);
					}}
					autoplay={false}
					loop={false}
					keepLastFrame={true}
					speed={3}
					src={colorSwitcher}
					style={{
						width: '48px',
					}}
				/>
			</x.button>
		</x.header>
	);
}
