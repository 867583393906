import { x } from '@xstyled/styled-components';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Styled from './style/Footer.styles';

const Footer = () => {
	const {
		contentfulLabels: {
			labels: {
				internal: { content },
			},
		},
	} = useStaticQuery(graphql`
		query FooterLabel {
			contentfulLabels(pageName: { eq: "footer" }) {
				labels {
					internal {
						content
					}
				}
			}
		}
	`);

	const labels = JSON.parse(content);

	return (
		<Styled.CoveredSection>
			<x.div
				display="flex"
				flexDirection={{ _: 'column', md: 'row' }}
				alignItems="center"
				justifyContent="space-between"
				maxWidth={1200}
				mx="auto"
			>
				<x.div mb={{ _: 4, md: 0 }}>
					<x.p
						color="text"
						fontSize={{ _: 'base', md: 'large' }}
						fontWeight={700}
					>
						{labels?.copyrights}
					</x.p>
				</x.div>
				<x.ul display="flex">
					<x.li mr={4} borderRadius="full">
						<x.a href={labels?.linkedin} target="_blank">
							<Styled.Linkedin />
						</x.a>
					</x.li>
					<x.li mr={4} borderRadius="full">
						<x.a href={labels?.twitter} target="_blank">
							<Styled.Twitter />
						</x.a>
					</x.li>
					<x.li borderRadius="full">
						<x.a href={labels?.github} target="_blank">
							<Styled.Github />
						</x.a>
					</x.li>
				</x.ul>
			</x.div>
		</Styled.CoveredSection>
	);
};
export default Footer;
