import styled, { css, system } from '@xstyled/styled-components';
import { motion } from 'framer-motion';
import { Link as _LinkRouter } from 'gatsby';

export const Link = styled.a`
	display: inline-block;
	position: relative;
	font-family: primary;
	letter-spacing: 1px;
	font-size: 18;

	&:focus {
		outline: none;
	}

	${system}
`;

export const LinkRouter = styled(_LinkRouter)`
	display: inline-block;
	position: relative;
	font-family: primary;
	letter-spacing: 1px;
	font-size: 18;
	text-decoration: none;

	&:focus {
		outline: none;
	}

	${system}
`;

export const Underline = styled(motion.div)`
	display: inline-block;
	width: 16px;
	height: 2px;
	position: absolute;
	bottom: -5px;
	left: 0;
	${({ color }) =>
		css`
			background-color: ${color || 'primary'};
		`};
`;
