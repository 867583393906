import React from 'react';
import {
	ColorModeProvider,
	Preflight,
	ThemeProvider,
	x,
	createGlobalStyle,
} from '@xstyled/styled-components';

import Header from 'layout/header/Header';
import Footer from 'layout/footer/Footer';

import theme from 'theme';

import '@fontsource/assistant/400.css';
import '@fontsource/assistant/600.css';
import '@fontsource/assistant/700.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Assistant;
		font-weight: 400;
		overflow-x: hidden;
  }
`;

export default function Layout({ children }) {
	return (
		<ThemeProvider theme={theme}>
			<Preflight />
			<GlobalStyle />
			<ColorModeProvider>
				<x.main
					transition="0.5s"
					bg="background"
					minHeight="100vh"
					px={{ _: 6, md: 8 }}
				>
					<x.div>
						<Header />
						{children}
						<Footer />
					</x.div>
				</x.main>
			</ColorModeProvider>
		</ThemeProvider>
	);
}
