import { defaultTheme } from "@xstyled/styled-components";

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    text: "#383636",
    background: "#ffffff",
    backgroundLight: "#f5f5f5",
    primary: "#F95353",
    primaryLight: "#ffb780",
    primaryDark: "#803700",
    modes: {
      dark: {
        text: "#DEDEDE",
        textLight: "#898989",
        background: "#1A1A1A",
        backgroundLight: "#202020",
        primary: "#F95353",
      },
    },
  },
  fonts: {
    primary: "Assistant",
  },
};

export default theme;
