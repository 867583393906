import React from 'react';
import { getColorModeInitScriptElement } from '@xstyled/styled-components';

import Layout from 'layout/Layout';

export function onRenderBody({ setPreBodyComponents }) {
	setPreBodyComponents([getColorModeInitScriptElement()]);
}

export function wrapPageElement({ element, props }) {
	return <Layout {...props}>{element}</Layout>;
}
