import styled, { x } from '@xstyled/styled-components';
import {
	Github as _Github,
	Linkedin as _Linkedin,
	Twitter as _Twitter,
} from '@styled-icons/evaicons-solid';

export const CoveredSection = styled(x.section).attrs((props) => ({
	mx: { _: -6, md: -8 },
	py: 8,
	px: { _: 6, md: 8 },
	...props,
}))`
	transition: 0.5s;
	background-color: backgroundLight;
`;

const icon = (icon) => {
	return styled(icon).attrs({ size: 40 })`
		color: text;
		transition: 0.25s;

		:hover {
			color: primary;
		}
	`;
};

export const Linkedin = icon(_Linkedin);

export const Twitter = icon(_Twitter);

export const Github = icon(_Github);
